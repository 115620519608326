<template>
  <ModalForm
    v-model="formData"
    title="Настройки фильтра"
    class="modal-title-page-edit"

    text-btn-confirm="Сохранить"
    btn-size="l"
    only-btn-submit

    @request="saveSectionFilterSettingHandler"
    @reject="onReject"
  >
    <VCheckbox
      v-for="item of filters"
      :key="item.id"
      :label="item.name"
      :name="item.search_field"
    />
  </ModalForm>
</template>

<script>
import ModalForm from '@/components/ui/Modal/ModalForm'
import VCheckbox from '@/components/Form/VCheckbox'
import FilterApi from '@/api/catalog/FilterApi'
import { showAlertError, showAlertSuccess } from '@/utils/store/alert'

export default {
  name: 'ModalCatalogCategoryFilterSetting',
  components: { VCheckbox, ModalForm },
  data: () => ({
    formData: {}
  }),
  created () {
    this.syncFormData()
  },
  computed: {
    filters () {
      return this.$store.state.catalog.filterList
    }
  },
  methods: {
    async saveSectionFilterSettingHandler (data) {
      try {
        this.$store.commit('SET_IS_SENDING_REQUEST_MODAL')

        const preparedData = this.prepare(data)

        await this.$store.dispatch('catalog/updateSectionFilterSetting', preparedData)
        const filter = await FilterApi.getAll({
            category_id: this.$route.params.categoryId
        })
        this.$store.commit('catalog/product/SET_FILTERVALUES', filter.data)
        showAlertSuccess('Настройки фильтра сохранены')
        this.$emit('request')
      } catch (e) {
        showAlertError(e)
      } finally {
        this.$store.commit('SET_IS_SENDING_REQUEST_MODAL', false)
      }
    },

    prepare (payload) {
      const data = []

      for (const field in payload) {
        data.push({
          filter_id: this.$store.getters['catalog/getFilterIdBy'](field)?.id,
          is_active: payload[field] || false
        })
      }

      return data
    },

    syncFormData () {
      const data = this.$store.getters['catalog/currentFilterSetting']

      this.formData = { ...data }
    },

    onReject () {
      this.$emit('reject')
    }
  }
}
</script>
