<template>
  <AppAccordion
    class="product-card box box--white"
    :id="`product-card--${product.id}`"
  >
    <template v-slot:header>
      <div class="product-card__header mb-20">
        <ProductCardSwiper
          class="product-card__swiper"
          :images="product.files_images"
        />

        <div class="product-card__content">
          <div class="product-card__top-row between mb-20 gap-s">
            <div class="column gap-xxs">
              <span v-if="isNew"
                class="flex gap-xxs color--white product-card__new">
                <svgicon
                  icon="new"
                  width="14"
                  height="14"
                />
                Новинка
              </span>

              <div class="flex gap-s">
                <InfoText
                  class="pseudo-line pseudo-line--vertical"
                  label="Арт."
                >
                  <VTooltip
                    class="product-card__article"
                    :text="product.article"
                    :max-length="70"
                  />
                </InfoText>

                <InfoInStock :count="amountInStock"/>
              </div>

              <InfoText
                v-if="product.additional_articles.length"
                label="Зам. арт."
              >
                <LinkList
                  class="product-card__link-list"
                  :links="product.additional_articles"
                  route-name="ProductEdit"
                  text-key="article"
                  params-key="productId"
                />
              </InfoText>
            </div>

            <div class="column gap-xxs">
                <InfoText
                    label="Создан"
                    :text="createdAt"
                />

                <InfoText
                    v-if="product.prices_updated && (hasNotCoatingPriece || hasCoatingPriece)"
                    label="Цена от"
                    :text="datePriceUpdated"
                />
            </div>
          </div>

          <div class="between gap-xxl product-card__info">
            <div class="column w-100">
              <h3 class="product-card__name subtitle mb-xs">
                <VTooltip
                  :popup-text="product.name"
                  :show-tip="product.name.length > 65"
                >
                  <router-link class="link-reset v-link hover--main" :to="goToProductEdit">
                    {{ product.name | stringMaxLength(65) }}
                  </router-link>
                </VTooltip>
              </h3>

              <VTooltip
                v-if="product.note_product"
                class="mb-20 color--red"
                :text="product.note_product"
                :max-length="70"
              />

              <ProductCardMainFields class="product-card__fields-grid" :product="product"/>
            </div>

            <ProductCardPrice
              v-if="product.prices.length"
              :price-list="product.prices"
              :coating="product.coating"
              :hasNotCoatingPriece="hasNotCoatingPriece"
              :hasCoatingPriece="hasCoatingPriece"
            />
          </div>
        </div>
      </div>
    </template>

    <ProductCardAccordion :product="product"/>
  </AppAccordion>
</template>

<script>
import AppAccordion from '@/components/ui/AppAccordion'
import InfoInStock from '@/components/views/Catalog/Info/InfoInStock'
import InfoText from '@/components/simple/text/InfoText'
import LinkList from '@/components/ui/link/LinkList/LinkList'
import ProductCardAccordion from '@/components/views/Catalog/ProductCard/ProductCardAccordion'
import ProductCardMainFields from '@/components/views/Catalog/ProductCard/ProductCardMainFields'
import ProductCardPrice from '@/components/views/Catalog/ProductCard/ProductCardPrice'
import ProductCardSwiper from '@/components/views/Catalog/ProductCard/ProductCardSwiper'
import VTooltip from '@/components/ui/VTooltip.vue'

import { mixinInStock } from '@/mixins/mixinInStock'

import Product from '@/classes/model/Catalog/Product'
import { formatDate } from '@/utils/format/date'

export default {
  name: 'ProductCard',
  components: {
    AppAccordion,
    InfoInStock,
    InfoText,
    LinkList,
    ProductCardAccordion,
    ProductCardMainFields,
    ProductCardPrice,
    ProductCardSwiper,
    VTooltip
  },
  mixins: [mixinInStock],
  props: {
    product: {
      type: Product,
      required: true
    }
  },
  data: () => ({
    hasNotCoatingPriece: false,
    hasCoatingPriece: false,
    amountInStock: 0
  }),
  computed: {
    goToProductEdit () {
      return {
        name: 'ProductEdit',
        params: {
          productId: this.product.id,
          categoryId: this.$route.params.categoryId
        }
      }
    },
    datePriceUpdated () {
      return formatDate(this.product.prices_updated)
    },
    isNew () {
      const difference = (new Date().getTime() - new Date(this.product.created_at)) / 86400000
      if (difference <= 14) return true
      else return false
    },
    createdAt () {
        return formatDate(this.product.created_at)
    }
  },
  created () {
    // this.setInStockBy(this.product.article)
    if (this.product.warehouse_hidden && this.product.warehouse_hidden === true) { return }
    this.amountInStock = +this.product.warehouse_amount - +this.product.warehouse_amount_reserved
  },
  mounted () {
    this.product.prices.forEach(item => {
      if (item.costNotCoating.length) this.hasNotCoatingPriece = true
      if (item.costCoating.length) this.hasCoatingPriece = true
    })
  }
}
</script>
