<template>
  <nav class="breadcrumbs">
    <ul class="breadcrumbs__list link-list">
      <li
        v-for="navItem of breadcrumbs"
        :key="navItem.id"
        class="breadcrumbs__item link-list__item flex"
      >
        <router-link
          class="breadcrumbs__link link-list__link"
          :to="{ name: navItem.routeName, params: navItem.routeParams }"
        >
          {{ navItem.label }}
        </router-link>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: 'VBreadcrumbs',
  props: {
    breadcrumbs: {
      type: Array,
      required: true
    }
  }
}
</script>
