<template>
  <FilterForm
    v-model="formValue"
    ref="filterForm"
    @request="request"
    @reset="reset"
  >
    <VSelect
      v-for="item of filters"
      :key="item.id"
      :label="item.name"
      :name="item.search_field"
      :options="getOptions(item.search_field)"
      multiple
      searchable
    />
  </FilterForm>
</template>

<script>
import FilterForm from '@/components/Form/FilterForm'
import VSelect from '@/components/Form/VSelect'
import { mapGetters, mapState, mapMutations } from 'vuex'
import { mixinFilterEmit } from '@/mixins/form/mixinFilterEmit'
import { prepareFormDataIdArray } from '@/utils/form/prepareFormDataIdArray'
import getValuesArray from '@/utils/array/getValuesArray'
import { checkAndSliceIds } from '@/utils/string'
import { generateId } from '@/utils/create'

export default {
  name: 'TheCatalogFilter',
  components: { VSelect, FilterForm },
  mixins: [mixinFilterEmit],
  computed: {
    ...mapState('records', {
      properties: 'properties'
    }),

    ...mapGetters('records', {
      dWireAll: 'dWireAll'
    }),
    ...mapState('catalog', {
      filterList: 'filterList',
      filterFormValue: 'filterFormValue'
    }),
    ...mapState('catalog/product', {
        filterValues: 'filterValues'
    }),
    ...mapGetters('catalog', {
      currentFilterSetting: 'currentFilterSetting'
    }),

    catalogHandbook () {
      return {
        productTypes: this.$store.state.handbook.productType.list,
        techBrands: this.$store.state.handbook.techBrand.list,
        techTypes: this.$store.state.handbook.techType.list,
        countries: this.$store.state.handbook.country.list
      }
    },

    filters () {
      return this.filterList.filter(item => this.currentFilterSetting[item.search_field])
    },

    getOptions () {
      return key => {
        key = checkAndSliceIds(key)
        switch (key) {
            case 'd_wires': {
                const opt = this.filterValues.find(item => item.search_field === 'd_wires')
                return opt.data.map(item => ({
                    name: String(item),
                    id: generateId()
                }))
            }
            case 'country': {
              const opt = this.filterValues.find(item => item.search_field === 'country_ids')
              return opt.data
            }
            case 'tech_brand': {
              const opt = this.filterValues.find(item => item.search_field === 'tech_brand_ids')
              return opt.data
            }
            case 'tech_type': {
              const opt = this.filterValues.find(item => item.search_field === 'tech_type_ids')
              return opt.data
            }
            case 'spring_type': {
              const opt = this.filterValues.find(item => item.search_field === 'spring_type_ids')
              return opt.data
            }
            case 'product_type': {
              const opt = this.filterValues.find(item => item.search_field === 'product_type_ids')
              return opt.data
            }
            case 'material': {
              const opt = this.filterValues.find(item => item.search_field === 'material_ids')
              return opt.data
            }
            default: return this.properties[key]
        }
      }
    },

    formValue: {
      get () {
        return this.filterFormValue
      },
      set (value) {
        this.SET_FILTER_FORM_VALUE(value)
      }
    }
  },
  methods: {
    ...mapMutations('catalog', {
      SET_FILTER_FORM_VALUE: 'SET_FILTER_FORM_VALUE'
    }),
    request (data) {
      const keyList = this.getKeyList()
      const dataFiltered = this.getFiltered(data, keyList)

      const preparedData = prepareFormDataIdArray(dataFiltered, keyList, { suffix: '' })

      if (preparedData.d_wires) {
        preparedData.d_wires = getValuesArray(data.d_wires, 'name')
      }

      this.$emit('request', preparedData)
    },

    getKeyList () {
      const list = []

      for (const key in this.currentFilterSetting) {
        if (!this.currentFilterSetting[key]) continue

        list.push(key)
      }

      return list
    },

    getFiltered (data, keys) {
      const filtered = {}

      for (const key of keys) {
        filtered[key] = data[key]
      }

      return filtered
    }
  }
}
</script>
