<template>
  <div :id="sliderId" class="product-card-swiper">
    <div
      class="product-card-swiper__wrapper swiper-wrapper"
      :class="!images.length && 'bg--box-overlay'"
    >
      <div
        v-for="(item, i) of images"
        :key="i"
        class="swiper-slide"
      >
        <img
          class="product-card-swiper__image swiper-lazy"
          :data-src="getURL(item)"
          :alt="item.file_name"
        >

        <div class="swiper-lazy-preloader"/>
      </div>
    </div>

    <div class="product-card-swiper__pagination swiper-pagination flex gap-xxs"/>
  </div>
</template>

<script>
import Swiper, { Pagination, Lazy } from 'swiper'
import { getURL } from '@/utils/files'

Swiper.use([Pagination, Lazy])

export default {
  name: 'ProductCardSwiper',
  props: {
    images: {
      type: Array,
      required: true
    }
  },
  data: () => ({
    productCardSlider: null
  }),
  computed: {
    sliderId () {
      return 'product-card-swiper--' + this._uid
    }
  },
  mounted () {
    if (!this.images?.length) return

    this.productCardSlider = new Swiper(`#${this.sliderId}`, {
      slidesPerView: 1,
      spaceBetween: 10,
      preloadImages: true,
      lazy: true,
      loadPrevNext: false,
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true
      }
    })
  },
  destroyed () {
    if (!this.productCardSlider) return
    this.productCardSlider.destroy()
  },
  methods: {
    getURL
  }
}
</script>
