<template>
  <div class="catalog">
    <VBreadcrumbs
      v-if="categoryId"
      class="catalog__breadcrumbs"
      :breadcrumbs="$store.state.catalog.breadcrumbs"
    />

    <p v-if="categoryDescription" class="catalog__description mb-m color--red">{{ categoryDescription }}</p>

    <div class="catalog__top between ai-c mb-m">
      <div class="catalog__filter-wrap flex gap-xxs">
        <VButton
          icon="filter"
          size="menu"
          color="primary-sm"
          :active="isFilter"
          @click.native="toggleFilter"
        />

        <VInput
          :value="search"
          placeholder="Поиск"
          type="search"
          width="calc(100% - 270px)"
          @onEnter="loadSearch"
        />

        <VSort
          v-model="sortValue"
          name="sort"
          class="sort-select"
          width="260px"
          :options="sortOptions"
          @change="setSort"
        />
      </div>

      <div class="flex gap-s">
        <VButton
          v-if="!categoryId && isAdmin"
          text="Экспортировать"
          size="sm"
          icon="export"
          @click.native="goToCategoriesExport"
        />

        <VButton
          v-if="!categoryId && permissionUpdateCatalogTitlePage"
          text="Редактировать титул"
          icon="edit"
          size="sm"
          @click.native="openModal('title-edit')"
        />

        <VButton
          v-if="!categoryId"
          text="Разделы"
          size="sm"
          icon="settings"
          @click.native="goToCategoriesEdit"
        />

        <VButton
          v-if="isAdmin"
          text="Настроить фильтр"
          size="sm"
          icon="filter"
          @click.native="openModal('filter-setting')"
        />

        <VButton
          v-if="categoryId && permissionCreateProduct"
          text="Добавить товар"
          icon="create"
          size="sm"
          color="primary-sm"
          @click.native="goToPage('ProductEdit', { categoryId })"
        />
      </div>
    </div>

    <TheCatalogFilter
      v-if="isFilter"
      ref="CatalogFilter"
      class="mb-m"
      @request="loadFilter"
      @reset="resetFilter"
    />

    <TheCatalogTitlePage class="mb-m" v-show="false" />

    <Spinner v-if="isLoading" />

    <div v-if="productsLength && !isLoading" class="column gap-m">
      <ProductCard
        v-for="item of products"
        :key="item.id"
        :product="item"
      />
    </div>

    <AppPagination
      v-if="pagination.count > 1"
      :current-page="pagination.page"
      :page-count="pagination.count"
      @setPage="loadPage"
    />

    <ModalTitlePageEdit
      v-if="isModal === 'title-edit'"
      @request="closeModal"
      @reject="closeModal"
    />

    <ModalCatalogCategoryFilterSetting
      v-if="isModal === 'filter-setting'"
      @request="closeModal"
      @reject="closeModal"
    />

    <router-view />
  </div>
</template>

<script>
import ModalCatalogCategoryFilterSetting from '@/components/views/Catalog/Modal/ModalCatalogCategoryFilterSetting'
import ModalTitlePageEdit from '@/components/ui/Modal/ModalTitlePageEdit'
import ProductCard from '@/components/views/Catalog/ProductCard/ProductCard'
import TheCatalogFilter from '@/components/views/Catalog/TheCatalogFilter'
import TheCatalogTitlePage from '@/components/views/Catalog/TheCatalogTitlePage'
import VBreadcrumbs from '@/components/Breadcrumbs/VBreadcrumbs'
import VButton from '@/components/simple/button/VButton'
import VInput from '@/components/Form/Vinput/VInput'
import VSort from '@/components/Form/VSort'

import { mixinIsFilter } from '@/mixins/mixinIsFilter'
import { mixinModal } from '@/mixins/modal/mixinModal'

import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
import { goToPage } from '@/utils/router'
import { showAlertError } from '@/utils/store/alert'
import AppPagination from '@/components/ui/AppPagination.vue'

const edit = 'edit'

export default {
  name: 'CatalogView',
  components: {
    AppPagination,
    TheCatalogFilter,
    ModalCatalogCategoryFilterSetting,
    ModalTitlePageEdit,
    ProductCard,
    TheCatalogTitlePage,
    VBreadcrumbs,
    VButton,
    VInput,
    VSort
  },
  mixins: [mixinIsFilter, mixinModal],

  data: () => ({
    sortValue: null
  }),

  computed: {
    ...mapState('catalog', {
      search: 'search',
      pagination: 'pagination',
      isLoading: 'isLoading'
    }),

    ...mapState('catalog/product', {
      products: 'products'
    }),

    productsLength () {
      return this.products.length
    },

    ...mapGetters('catalog', {
      categoryDescription: 'categoryDescription',
      getSortValue: 'getSortValue'
    }),

    ...mapGetters('permission', {
      isAdmin: 'isAdmin',
      permissionUpdateCatalogTitlePage: 'permissionUpdateCatalogTitlePage',
      permissionCreateProduct: 'permissionCreateProduct'
    }),
    categoryId () {
      return this.$route.params.categoryId
    },
    sortOptions () {
      return [
        { 
          id: 1,
          name: 'От новых к старым',
          value: '-created_at'
        },
        { 
          id: 2,
          name: 'От старых к новым',
          value: 'created_at'
        },
        { 
          id: 3,
          name: 'По уменьшению d пров.',
          value: '-product_d_wire'
        },
        { 
          id: 4,
          name: 'По возрастанию d пров.',
          value: 'product_d_wire'
        }
      ]
    }
  },

  watch: {
    categoryId: {
      async handler (id) {
        if (!this.$route.params.categoryId) {
          this.sortValue = this.sortOptions[0]
          this.SET_SORT_VALUE(this.sortOptions[0])
          this.SET_SORT('-created_at') 
        } else {
          this.sortValue = this.sortOptions[3]
          this.SET_SORT_VALUE(this.sortOptions[3])
          this.SET_SORT('product_d_wire') 
        }
        this.SET_PAGE(1)
        await this.setSidebarAndLoadProducts(id)
      },
      immediate: false
    }
  },

  created () {
    Promise.all([
      this.loadCatalogHandbook(),
      this.fetchAndSetFilters()
    ])
    if (this.categoryId === edit) {
      this.goToCategoriesEdit()
    }
  },

  async mounted () {
    await this.initFetch()
    this.sortValue = this.getSortValue
    await this.getProductsBy(this.categoryId)
    await this.$store.dispatch('scroll/scrollToNode')
  },

  methods: {
    goToPage,
    ...mapActions('handbook', {
      loadCatalogHandbook: 'loadCatalogHandbook'
    }),
    ...mapMutations('catalog', {
      RESET_PRODUCTS: 'product/RESET_PRODUCTS',
      SET_SEARCH: 'SET_SEARCH',
      RESET_SEARCH: 'RESET_SEARCH',
      SET_FILTER: 'SET_FILTER',
      RESET_FILTER: 'RESET_FILTER',
      SET_PAGE: 'SET_PAGE',
      SET_SORT: 'SET_SORT',
      SET_SORT_VALUE: 'SET_SORT_VALUE'
    }),
    ...mapActions('catalog', {
      fetchAndSetFilters: 'fetchAndSetFilters',
      getAllCategories: 'categories/getAllCategories',
      fetchAndSetHome: 'home/fetchAndSetHome',
      getProductsBy: 'product/getProductsBy',
      destroyProduct: 'product/destroyProduct'
    }),

    async initFetch () {
      try {
        await Promise.all([
          this.getAllCategories(),
          this.fetchAndSetHome()
        ])

        await this.setSidebarAndLoadProducts(this.categoryId)
      } catch (e) {
        showAlertError(e)
      }
    },

    async setSidebarAndLoadProducts (categoryId) {
      await this.$store.dispatch('menu/setSidebarMenu')

      if (categoryId !== edit) {
        await this.loadProducts()
      } else {
        this.RESET_PRODUCTS()
        this.clearFilterForm()
      }
    },

    goToCategoriesEdit () {
      goToPage('CatalogCategoriesEdit')
    },

    goToCategoriesExport () {
        goToPage('CatalogCategoriesExport')
    },

    loadSearch (value = '') {
      this.SET_SEARCH(value)
      this.loadPage(1)
    },

    loadFilter (filterData) {
      this.SET_FILTER(filterData)
      this.loadPage(1)
    },
    resetFilter () {
      this.RESET_FILTER()
      this.loadPage(1)
    },

    loadPage (num = 1) {
      this.SET_PAGE(num)
      this.loadProducts()
    },

    resetQuery () {
      this.RESET_SEARCH()
      this.RESET_FILTER()
      this.SET_PAGE(1)
    },

    async loadProducts () {
      await this.getProductsBy(this.categoryId)
    },

    clearFilterForm () {
      if (!this.isFilter) return
      this.$refs.CatalogFilter.$refs.filterForm.$refs.form.clear()
      this.RESET_FILTER()
      this.loadPage(1)
    },
    setSort (data) {
      this.sortValue = data
      this.SET_SORT_VALUE(data)
      this.SET_SORT(data.value)
      this.loadPage(1)
    }
  }
}
</script>
