<template>
  <div class="attachment-files">
    <AttachmentFile
      v-for="(file, i) in files"
      :key="i"
      :file="file"
      :max-length-name="lengthName"
      :has-button-remove="isEnabledRemoveFile"
      :with-preview="withPreview"
      @show-file="openModalPreview(i)"
      @remove-file="onRemoveFile(file.id, i)"
    />

    <ModalFilesPreview
      v-if="isModal"
      :files="files"
      :init-active-file-idx="fileIdxToPreview"
      @close="closeModal"
    />
  </div>
</template>

<script>
import AttachmentFile from './AttachmentFile'
import ModalFilesPreview from '../ModalFilesPreview'
import { mixinModal } from '@/mixins/modal/mixinModal'

export default {
  name: 'VAttachmentFilesList',
  components: {
    ModalFilesPreview,
    AttachmentFile
  },
  mixins: [mixinModal],
  props: {
    files: {
      type: [Array, FileList],
      required: true
    },
    lengthName: {
      type: Number,
      required: false,
      default: 30
    },
    isEnabledRemoveFile: {
      type: Boolean,
      required: false,
      default: false
    },
    withPreview: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: () => ({
    fileIdxToPreview: 0
  }),
  methods: {
    openModalPreview (idx) {
      this.fileIdxToPreview = idx
      this.openModal()
    },
    onRemoveFile (id, index) {
      this.$emit('onRemoveFile', { id, index })
    }
  }
}
</script>
