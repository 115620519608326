<template>
  <div>
    <div class='column gap-xs'>
      <InfoText
        v-if="product.d_wire"
        label="Диаметр проволоки (мм)"
        :text="product.d_wire"
      />

      <InfoText
        v-if="product.diameter"
        :label="diameterLabel"
        :text="product.diameter"
      />

      <InfoText
        v-if="product.length"
        :label="lengthLabel"
        :text="product.length"
      />

      <InfoText
        v-if="product.width && visibleWidth"
        label="Ширина (мм)"
        :text="product.width"
      />
    </div>

    <div class='column gap-xs'>
      <InfoText
        v-if="product.n_n1 && visibleTurns"
        :label="turnsLabel"
        :text="product.n_n1"
      />

      <InfoText
        v-if="product.product_winding_dir"
        label="Направление навивки"
        :text="product.product_winding_dir.name"
      />

      <InfoText
        v-if="product.weight"
        label="Вес (кг)"
        :text="product.weight"
      />

      <InfoText
        v-if="product.material"
        label="Сталь"
        :text="product.material"
        is-handbook
      />

      <InfoText 
        v-if="product.tech_brand.length" 
        label="Марка техники"
        is-handbook
      >
        <ul class="link-list flex f-wrap gap-xxs">
          <li
            v-for="(item, index) in product.tech_brand"
            :key="index"
            class="link-list__item"
          >
            <span>{{ item.name }}</span>
          </li>
        </ul>
      </InfoText>
    </div>
  </div>
</template>

<script>
import InfoText from '@/components/simple/text/InfoText'
import Product from '@/classes/model/Catalog/Product'
import { mixinCatalogProductMainFields } from '@/mixins/views/Catalog/Product/mixinCatalogProductMainFields'

export default {
  name: 'ProductCardMainFields',
  components: { InfoText },
  mixins: [mixinCatalogProductMainFields],
  props: {
    product: {
      type: Product,
      required: true
    }
  },
  computed: {
    springTypeId () {
      return this.product.spring_type_id
    }
  }
}
</script>
