<template>
  <div
    class="input"
    :style="{ width }"
  >

    <Multiselect
      v-model="inputValue"
      :class="[{'multiselect__multi': multiple}, {'input_error': hasValidationError}]"
      :placeholder="placeholder"
      :options="shownOptions"
      :label="itemText"
      :track-by="itemText"
      :tabindex="0"
      :options-limit="50"
      :maxHeight="300"
      :searchable="false"
      :disabled="disabled"
      :close-on-select="!multiple"
      :allow-empty="false"
      :multiple="multiple"
      :loading="loading"
      :showLabels="false"
      :resetAfter="false"
      :internal-search="false"
      :custom-validation="customValidation"
      @select="$emit('select')"
      @open="$emit('open')"
      @close="$emit('close')"
      @search-change="searchHandler"
    >
      <template v-slot:singleLabel="{ option }">
        <slot name="singleLabel" :option="option">
          {{ option[itemText] }}
        </slot>
      </template>

      <template v-slot:tag="{ option, remove }">
        <slot name="tag" :option="option" :remove="remove" />
      </template>

      <template v-slot:option="{ option }">
        <slot name="option" :option="option"/>
      </template>

      <template v-slot:noResult>Ничего не найдено</template>
      <template v-slot:noOptions>Список пуст</template>
    </Multiselect>

    <slot v-if="hasValidationError" />
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import Base from './Vinput/Base/Base.vue'
import Vue from 'vue'

export default {
  name: 'VSort',
  inject: {
    formData: {
      from: 'formData',
      default: null
    }
  },
  extends: Base,
  components: {
    Multiselect
  },
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    value: {
      type: [Object, Array],
      default: null
    },
    placeholder: {
      type: String,
      default: 'Параметр сортировки'
    },
    itemText: {
      type: String,
      default: 'name'
    },
    options: {
      type: Array,
      default: () => []
    },
    allowEmpty: {
      type: Boolean,
      default: true
    },
    multiple: {
      type: Boolean,
      default: false
    },
    searchable: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    shownOptions: [],
    timeoutSearch: null
  }),
  computed: {
    inputValue: {
      get () {
        if (this.formData && this.name) {
          return this.formData[this.name]
        } else {
          return this.value
        }
      },
      set (val) {
        if (this.formData && this.name) {
          if (this.multiple && !val.length) {
            val = null
          }
          Vue.set(this.formData, this.name, val)
        }
        this.$emit('change', val)
      }
    },
    rules () {
      const validators = {}

      if (this.required) {
        if (!this.inputValue) {
          validators.required = () => false
        }

        if (this.inputValue && this.inputValue instanceof Object) {
          validators.required = Object.keys(this.inputValue).length
        }
        if (this.inputValue && this.inputValue instanceof Array) {
          validators.required = () => !!this.inputValue?.length
        }
      }

      if (this.customValidation) {
        validators.customValidation = value => this.customValidation(value)
      }

      return validators
    }
  },
  watch: {
    options () {
      this.syncOptions()
    }
  },
  mounted () {
    this.syncOptions()
  },
  methods: {
    searchHandler (value) {
      const normalizedSearch = value.toLowerCase().trim()

      if (!normalizedSearch) {
        return this.syncOptions()
      }

      const toRegExp = normalizedSearch.replace(/[.,]/, '[.,]')
      const regexp = new RegExp(toRegExp)

      this.shownOptions = this.options
        .filter(option => this.filterOption(option, regexp))
        .sort((a, b) => this.sortOption(a, b, normalizedSearch))
    },

    filterOption (option, regexp) {
      const normalizedLabel = this.getNormalizedOptionLabel(option)
      return regexp.test(normalizedLabel)
    },

    sortOption (a, b, search) {
      const normalizedA = this.getNormalizedOptionLabel(a)
      const normalizedB = this.getNormalizedOptionLabel(b)

      const startA = normalizedA.startsWith(search)
      const startB = normalizedB.startsWith(search)

      if (startA && !startB) return -1
      if (!startA && startB) return 1
      return 0
    },

    getNormalizedOptionLabel (option) {
      return option[this.itemText].toLowerCase()
    },

    syncOptions () {
      this.shownOptions = this.options
    }
  }
}
</script>

<style>
.input__title {
  line-height: 30px;
  margin-bottom: 10px;
}
</style>
