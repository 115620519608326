<template>
  <div class="catalog-title-page column gap-m">
    <div
      class="catalog-title-page__img-wrap"
      :class="!src && 'catalog-title-page__img-wrap--default'"
      :style="styles"
    />

    <p class="catalog-title-page__description" v-html="description"/>
  </div>
</template>

<script>
export default {
  name: 'TheCatalogTitlePage',

  computed: {
    description () {
      return this.$store.state.catalog.home.text
    },

    styles () {
      if (!this.src) return {}

      return {
        'background-image': `url(${this.src})`
      }
    },
    src () {
      return this.$store.getters['catalog/home/imageSrc']
    }
  }
}
</script>
