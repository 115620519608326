<template>
  <ModalForm
    v-model="formData"
    title="Редактировать титул"
    class="modal-title-page-edit"

    text-btn-confirm="Сохранить"
    btn-size="l"
    only-btn-submit

    @request="editTitlePageHandler"
    @reject="onReject"
  >
    <InputFileDrag name="files" @onRemoveFile='addFileToDelete' />

    <VInput
      label="Текст"
      name="text"
      type="textarea"
      height="120px"
    />
  </ModalForm>
</template>

<script>
import ModalForm from '@/components/ui/Modal/ModalForm'
import VInput from '@/components/Form/Vinput/VInput'
import InputFileDrag from '@/components/Form/VInputFiles/InputFileDrag'
import { showAlertError, showAlertSuccess } from '@/utils/store/alert'
import { mapActions } from 'vuex'

export default {
  name: 'ModalTitlePageEdit',
  components: { InputFileDrag, VInput, ModalForm },

  data: () => ({
    formData: {
      files: [],
      text: ''
    },
    fileIdToDelete: null
  }),

  mounted () {
    this.syncFormData()
  },
  destroyed () {
    this.resetFileToDelete()
  },

  methods: {
    ...mapActions('catalog/home', {
      updateHome: 'updateHome',
      deleteHomeFile: 'deleteHomeFile'
    }),
    async editTitlePageHandler (data) {
      try {
        this.$store.commit('SET_IS_SENDING_REQUEST_MODAL')

        const formData = new FormData()
        formData.append('text', data.text)

        const file = data.files[0]

        if (this.fileIdToDelete) {
          await this.deleteHomeFile(this.fileIdToDelete)
          this.resetFileToDelete()
        }

        if (!file?.id) {
          formData.append('file', file)
        }

        await this.updateHome(formData)

        showAlertSuccess('Главная страница обновлена')

        this.$emit('request')
      } catch (e) {
        showAlertError(e)
      } finally {
        this.$store.commit('SET_IS_SENDING_REQUEST_MODAL', false)
      }
    },

    syncFormData () {
      const { text, file } = this.$store.state.catalog.home
      this.formData.text = text

      if (!file) return
      this.formData.files.push(...[file])
    },

    addFileToDelete (id) {
      this.fileIdToDelete = id
    },
    resetFileToDelete () {
      this.fileIdToDelete = null
    },

    onReject () {
      this.$emit('reject')
    }
  }
}
</script>
