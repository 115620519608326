<template>
  <ul class="link-list">
    <li
      v-for="item of links"
      :key="item.id"
      class="link-list__item flex"
    >
      <router-link
        class="link-list__link"
        :to="{ name: routeName, params: { [paramsKey]: item[paramsValue]} }"
      >
        {{ item[textKey] }}
      </router-link>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'LinkList',
  props: {
    links: {
      type: Array,
      required: true
    },
    routeName: {
      type: String,
      required: true
    },
    paramsKey: {
      type: String,
      default: 'id'
    },
    paramsValue: {
      type: String,
      default: 'id'
    },
    textKey: {
      type: String,
      default: 'name'
    }
  }
}
</script>
