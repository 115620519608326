var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"input",style:({ width: _vm.width })},[_c('Multiselect',{class:[{'multiselect__multi': _vm.multiple}, {'input_error': _vm.hasValidationError}],attrs:{"placeholder":_vm.placeholder,"options":_vm.shownOptions,"label":_vm.itemText,"track-by":_vm.itemText,"tabindex":0,"options-limit":50,"maxHeight":300,"searchable":false,"disabled":_vm.disabled,"close-on-select":!_vm.multiple,"allow-empty":false,"multiple":_vm.multiple,"loading":_vm.loading,"showLabels":false,"resetAfter":false,"internal-search":false,"custom-validation":_vm.customValidation},on:{"select":function($event){return _vm.$emit('select')},"open":function($event){return _vm.$emit('open')},"close":function($event){return _vm.$emit('close')},"search-change":_vm.searchHandler},scopedSlots:_vm._u([{key:"singleLabel",fn:function(ref){
var option = ref.option;
return [_vm._t("singleLabel",function(){return [_vm._v(" "+_vm._s(option[_vm.itemText])+" ")]},{"option":option})]}},{key:"tag",fn:function(ref){
var option = ref.option;
var remove = ref.remove;
return [_vm._t("tag",null,{"option":option,"remove":remove})]}},{key:"option",fn:function(ref){
var option = ref.option;
return [_vm._t("option",null,{"option":option})]}},{key:"noResult",fn:function(){return [_vm._v("Ничего не найдено")]},proxy:true},{key:"noOptions",fn:function(){return [_vm._v("Список пуст")]},proxy:true}],null,true),model:{value:(_vm.inputValue),callback:function ($$v) {_vm.inputValue=$$v},expression:"inputValue"}}),(_vm.hasValidationError)?_vm._t("default"):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }