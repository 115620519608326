<template>
  <div class="product-card__accordion column gap-s">
    <h4 v-if="characteristic">Общие характеристики</h4>

    <div class="product-card__fields-grid">
      <InfoText
        v-if="product.tech_type"
        label="Тип техники"
        :text="product.tech_type"
        is-handbook
      />

      <InfoText
        v-if="product.product_type"
        label="Вид продукции"
        :text="product.product_type"
        is-handbook
      />

      <InfoText
        v-if="product.country"
        label="Страна"
        :text="product.country"
        is-handbook
      />

      <InfoText
        v-if="product.color"
        label="Цвет"
        :text="product.color"
        is-handbook
      />
    </div>

    <h4>Файлы</h4>

    <span v-if="!product.files_sales.length">Нет файлов</span>

    <VAttachmentFilesList
      v-else
      :files="product.files_sales"
      with-preview
    />

    <div class="d-flex gap-xxl">
      <div v-if="product.applicability" class="product-card__description product-card__description--applicability column gap-s">
        <h4>Применяемость</h4>

        <p>{{ product.applicability }}</p>
      </div>

      <div v-if="product.note" class="product-card__description product-card__description--note column gap-s">
        <h4>Примечание</h4>

        <p>{{ product.note }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import InfoText from '@/components/simple/text/InfoText'
import VAttachmentFilesList from '@/components/files/AttachmentFiles/AttachmentFilesList'
import Product from '@/classes/model/Catalog/Product'

export default {
  name: 'ProductCardAccordion',
  components: { VAttachmentFilesList, InfoText },
  props: {
    product: {
      type: Product,
      required: true
    }
  },
  computed: {
    characteristic () {
      return this.product.tech_type || this.product.product_type || this.product.country || this.product.color
    }
  }
}
</script>
