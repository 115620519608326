<template>
  <div class="product-card-price">
    <div v-if="hasNotCoatingPriece"
      class="product-card-price__head product-card-price__col--2 center"
    >
      Без покрытия
    </div>

    <VTooltip
      v-if="hasCoatingPriece"
      class="product-card-price__head"
      :class="hasNotCoatingPriece ? 'product-card-price__col--3' : 'product-card-price__col--2'"
      :text="coating ? coating.name : 'С покрытием'"
      :max-length="11"
    />

    <div
      v-for="item of priceList"
      :key="item.id"
      class="d-contents"
    >
      <VTooltip
        v-if="hasCoatingPriece || hasNotCoatingPriece"
        class="product-card-price__name"
        :text="item.name"
        :max-length="18"
      />
      <div class="product-card-price__cost center" v-if="hasNotCoatingPriece">{{ item.costNotCoating }}</div>
      <div v-if="hasCoatingPriece" class="product-card-price__cost product-card-price__cost--coating center">{{ item.costCoating }}</div>
    </div>
  </div>
</template>

<script>
import VTooltip from '@/components/ui/VTooltip'
import stringMaxLengthFilter from '@/filters/stringMaxLength.filter'

const coatingNameMaxLength = 20

export default {
  name: 'ProductCardPrice',
  components: { VTooltip },
  props: {
    priceList: {
      type: Array,
      required: true
    },
    coating: {
      type: Object,
      required: false
    },
    hasCoatingPriece: {
      type: Boolean,
      default: false
    },
    hasNotCoatingPriece: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    coatingNameHasTooltip () {
      return this.coating?.name.length > coatingNameMaxLength
    },

    coatingNameFiltered () {
      return stringMaxLengthFilter(this.coating?.name, coatingNameMaxLength)
    }
  }
}
</script>
