<template>
  <div class="file">
    <img
      v-if="iconType"
      class="file__icon"
      :src="`/img/svg/files/icon-file-type--${iconType}.svg`"
    >

    <svgicon
      v-else
      class="file__icon file__icon--default svg-icon--file"
      icon="file"
      width="25"
      height="25"
    />

    <VTooltip
      :popupText="fileName"
      :showTip="hasTooltip"
    >
      <a
        :href="fileURL"
        target="_blank"
        class="file__name"
        @click="showFile"
      >
        {{ fileName | stringMaxLength(maxLengthName) }}
      </a>
    </VTooltip>

    <span class="file__size">{{ fileSizeMb }}&nbsp;Мб</span>

    <button
      v-if="hasButtonRemove"
      class="btn-reset btn-cross btn-cross--remove as-fs"
      type="button"
      @click="onRemove"
    />
  </div>
</template>

<script>
import VTooltip from '@/components/ui/VTooltip'
import { mixinFile } from '@/mixins/files/mixinFile'
import { getFileTypeBy } from '@/utils/files'

export default {
  name: 'AttachmentFile',
  components: { VTooltip },
  mixins: [mixinFile],
  props: {
    maxLengthName: {
      type: Number,
      required: false,
      default: 30
    },
    hasButtonRemove: {
      type: Boolean,
      required: false,
      default: false
    },
    withPreview: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    hasTooltip () {
      return this.fileName.length > this.maxLengthName
    },
    fileSizeMb () {
      return (this.file.size / 1024 / 1024).toFixed(1)
    },
    iconType () {
      switch (this.fileType) {
        case 'docx':
        case 'doc': return 'doc'
        case 'xlsx':
        case 'xls': return 'xls'
        case 'pdf': return 'pdf'
        case 'png': return 'png'
        case 'webp': return 'webp'
        case 'gif': return 'gif'
        case 'jpg':
        case 'jpeg': return 'jpeg'
        default: return ''
      }
    },
    fileType () {
      return getFileTypeBy(this.fileName)
    }
  },
  methods: {
    showFile (event) {
      if (this.withPreview) {
        event.preventDefault()
        this.$emit('show-file')
      }
    },
    onRemove () {
      this.$emit('remove-file')
    }
  }
}
</script>
